import React from 'react';
import header_img from '../../IMG/Header_img.png';
import header_mobileview from '../../IMG/header_mobileview.png';
import '../../CSS/Header.css';
import { Col, Container, Image, Row } from 'react-bootstrap';
import ResponsiveImage from '../Responsive_img/ResponsiveImage';

import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation_bar from '../navbar compo/Navigation_bar';

import building1 from '../../IMG/building1.png';
import building2 from '../../IMG/building2.png';
import building3 from '../../IMG/building 3.png';


import bulding_photo from '../../IMG/bulding1.png'
import bulding_photo2 from '../../IMG/building2.png'
import bulding_photo3 from '../../IMG/building 3.png'
import '../../CSS/Header.css'

import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Header() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <section id='home'>
            <Container fluid className='m-0 p-0'>
                <ResponsiveImage mobileSrc={header_mobileview} desktopSrc={header_img} />
            </Container>
            <Navigation_bar />
            <Container>
                <Row>
                    <Col lg={9} md={9} sm={12}></Col>
                    <Col lg={3} md={3} sm={12} className="bannerform my-5 sticky-top d-none d-md-block">
                        <Container>
                            <div className="contact-form ">
                                <h2 className="py-3">CONTACT</h2>
                                <form className="sticky-top ">
                                    <input
                                        type="text"
                                        name="fullName"
                                        className="bannerinp"
                                        placeholder="Full name"
                                        required
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email Address"
                                        className="bannerinp"
                                        required
                                    />
                                    <input
                                        type="tel"
                                        name="mobileNumber"
                                        placeholder="Mobile Number"
                                        className="bannerinp"
                                        required
                                    />
                                    <textarea
                                        name="message"
                                        placeholder="Message"
                                        className="bannertxtarea"
                                        required
                                    ></textarea>
                                    <div id='send_btn_desktop'>
                                        <button
                                            type="submit"
                                            className="bannerbtn px-4 py-2  me-4 mr-5 float-end"
                                        >
                                        Send
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Container fluid >
                <Row>
                    <Col lg={6} md={6} sm={12}></Col>
                    <Col lg={6} md={6} sm={12}>
                        <Row>
                            <Col lg={3} md={3} sm={12} >
                                <Image src={bulding_photo} fluid id='header_imgs' style={{ height: '275px' }} className='d-none d-md-block' />
                            </Col>
                            <Col lg={3} md={3} sm={12} >
                                <Image src={bulding_photo2} fluid id='header_imgs' className='d-none d-md-block' />
                            </Col>
                            <Col lg={6} md={6} sm={12} >
                                <Image src={bulding_photo3} fluid id='header_imgs' className='d-none d-md-block' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Button variant="primary" onClick={handleShow} className='d-block d-md-none ' id='clickme_btn'>
                Get Started
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><b>CONTACT</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPassword">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                        <div className='send_btn_mobile'>
                            <Button variant="primary" type='submit'>Send</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default Header;
