import React, { useState, useEffect } from 'react';
import '../../CSS/Header.css';
import Image from 'react-bootstrap/Image';
const ResponsiveImage = ({ mobileSrc, desktopSrc }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        // <img src={mobileSrc} alt="Mobile View"  className=' img-fluid' width={'100%'} />
        <Image src={mobileSrc} fluid id='header_img' style={{height:'400px'}}/>
      ) : (
        <Image src={desktopSrc} fluid id='header_img'/>
      )}
    </div>
  );
};

export default ResponsiveImage;