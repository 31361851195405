import Aboutus from './Components/Aboutus/Aboutus';
import Benefits from './Components/Benefits/Benefits';
import Buildingimg from './Components/Building_img/Buildingimg';
import Cards_trakify from './Components/Cards_trakify/Cards_trakify';
import AccordionUsage from './Components/FAQ/AccordionUsage';
import Footer from './Components/Footer/Footer';
import Footer1 from './Components/Footer/Footer1';
import Header from './Components/Header_page/Header';
import Navbar from './Components/navbar compo/Navbar';
import Navigation_bar from './Components/navbar compo/Navigation_bar';
import Feature_of_trakify from './Feature of trakify/Feature_of_trakify';
import logo from './logo.svg';
import Trakify_application_banner from './Trakify Application banner/Trakify_application_banner';


function App() {
  return (
    <>

       <Header />
      <Aboutus />
      <Feature_of_trakify /> 
      <Cards_trakify />
      <Trakify_application_banner />
      <Benefits />
      <AccordionUsage />
      <Footer />
      <Footer1 />
    </>
  );
}

export default App;
