import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import aboutimg from '../../IMG/aboutimg.png'
import '../../CSS/aboutus.css'

function Aboutus() {
    return (
        <section id='about'>
    
            <Container fluid className='About_us_content '>
                <Row className='About_us_content'>
                    <Col lg={6} md={6} sm={12} className='p-0'>
                        <Image src={aboutimg} fluid id='aboutimg'/>
                    </Col>
                    <Col lg={6} md={6} sm={12} id='aboutpara'>
                        <h1 id='aboutus_name'>ABOUT US</h1>
                        <p>Trakify's purpose is to provide builders and developers executives with a comprehensive and user-friendly application to streamline the tracking and management of flats post-construction. The application aims to enhance the efficiency of flat showcases by allowing executives to easily select, update, and communicate the status of individual flats within a given project.</p>
                    </Col>
                </Row>
            </Container>
            {/*  */}

        </section>
    )
}

export default Aboutus
