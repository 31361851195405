import React from 'react'
import Image from 'react-bootstrap/Image';
import trakify_applicationImg from '../IMG/trakify_application desktop view.png'
import ResponsiveImage from '../Components/Responsive_img/ResponsiveImage';
import mobile_view from '../IMG/trakify_application (2).png'
function Trakify_application_banner() {
    return (
        <>
              <ResponsiveImage mobileSrc={mobile_view} desktopSrc={trakify_applicationImg} /> 
             {/* <Image src={trakify_applicationImg} fluid /> */}
        </>
    )
}

export default Trakify_application_banner
