import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import '../../CSS/cards.css'
import Image from 'react-bootstrap/Image';

import OTP_img from '../../IMG/OTP_card.png'
import arrow from '../../IMG/arrow.png'
import arrow_down from '../../IMG/arrow down.png'
import project_card from '../../IMG/ProjectLits_crad.png'
import Arquitetos_cardimg from '../../IMG/Aquitetos_card_img.png'

import Arquitetos_associados_cardimg from '../../IMG/Aquiteos_associados_card.png'
import Arquitetos_card_img2 from '../../IMG/Aruitetos_card_img2.png'
import Responsive_arrow from '../../Responsive Arrow/Responsive_arrow';
import conatct_img from '../../IMG/contact_us_card.png'

function Cards_trakify() {
    return (
        <section id='product'>
            <h1 style={{ textAlign: 'center' }} className='mt-0'>HOW IT WORKS</h1>
            <Container fluid className='card_back'>
                <Container>
                    <Row>
                        <Col lg={3} md={5} sm={12}>
                            <Card id='Card_aaplication'>
                                <Card.Body>
                                    <p>Registration And Login</p>
                                </Card.Body>
                            </Card>
                            <Image src={OTP_img} fluid id='Card_aaplication1' />
                        </Col>
                        <Col lg={1} md={2} sm={12}>
                            <div className='img_arrow1'>
                                <Responsive_arrow mobileSrc={arrow_down} desktopSrc={arrow} />
                            </div>
                        </Col>
                        
                        <Col lg={3} md={5} sm={12}>
                            <Card id='Card_aaplication'>
                                <Card.Body>
                                    <p>Select Project</p>
                                </Card.Body>
                            </Card>
                            <Image src={project_card} fluid id='Card_aaplication1' />
                        </Col>
                        <Col lg={1} md={2} sm={12}>
                            <div className='img_arrow1'>
                                <Responsive_arrow mobileSrc={arrow_down} desktopSrc={arrow} />
                            </div>
                        </Col>
                        <Col lg={3} md={5} sm={12}>
                            <Card id='Card_aaplication'>
                                <Card.Body>
                                    <p>Navigate Through Wings And Floors</p>
                                </Card.Body>
                            </Card>
                            <Image src={Arquitetos_cardimg} fluid id='Card_aaplication1' />
                        </Col>
                        
                        <Col lg={1} md={2} sm={12} >
                            <div className='img_arrow1'>
                                <Responsive_arrow mobileSrc={arrow_down} />
                            </div>
                        </Col>
                        
                        
                        <Col lg={3} md={5} sm={12} >
                            <Card id='Card_aaplication' className='mt-5'>
                                <Card.Body>
                                    <p>Update Flat Status</p>
                                </Card.Body>
                            </Card>
                            <Image src={Arquitetos_associados_cardimg} fluid id='Card_aaplication1' className='mt-5'/>
                        </Col>
                        <Col lg={1} md={2} sm={12} className='mt-5'>
                            <div className='img_arrow1'>
                                <Responsive_arrow mobileSrc={arrow_down} desktopSrc={arrow} />
                            </div>
                        </Col>
                        <Col lg={3} md={5} sm={12} >
                            <Card id='Card_aaplication' className='mt-5'>
                                <Card.Body>
                                    <p>Take Action</p>
                                </Card.Body>
                            </Card>
                            <Image src={Arquitetos_card_img2} fluid id='Card_aaplication2' className='mt-5'/>
                        </Col>
                        <Col lg={1} md={2} sm={12} className='mt-5'>
                            <div className='img_arrow1'>
                                <Responsive_arrow mobileSrc={arrow_down} desktopSrc={arrow} className='d-none d-md-block' />
                            </div>
                        </Col>
                        <Col lg={3} md={5} sm={12} >
                            <Card id='Card_aaplication' className='mt-5'>
                                <Card.Body>
                                <p>Confirmation</p>
                                </Card.Body>
                            </Card>
                            <Image src={conatct_img} fluid id='Card_aaplication2' className='mt-5'/>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section>
    )
}

export default Cards_trakify
