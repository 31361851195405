import React from 'react';
import Image from 'react-bootstrap/Image';
import footer2 from '../../IMG/LOGO WHITE 1.png';
import '../../CSS/footer2.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from '@mui/material';

function Footer1() {
  return (
    <>
      <div className='BACK_footer2_img'>
      <div className="text-center pt-3">
          <Image src={footer2} fluid id='footer_img' width={'200px'} />
        </div>
        <Container>
          <Navbar expand="lg">
            <Nav className="mx-auto">
              <Nav.Link className='text-white text-center' href='#home'>HOME</Nav.Link>
              <Nav.Link className='text-white text-center' href='#services'>SERVICE</Nav.Link>
              <Nav.Link className='text-white text-center' href='#product'>PRODUCT</Nav.Link>
              <Nav.Link className='text-white text-center' href='#contact'>CONTACT</Nav.Link>
              <div className='text-white text-center'>
                <div>
                  <a href='https://www.facebook.com/profile.php?id=61561955100163&mibextid=LQQJ4d '>
                    <i className="fab fa-facebook text-white" style={{ fontSize: "30px" }} id='icon'></i>
                  </a>
                  <a href='https://www.instagram.com/trakify.official/?igsh=MTgybDFxbjRqaDJrdg%3D%3D&utm_source=qr' target='_blank'>
                    <i className="fab fa-instagram text-white" style={{ fontSize: "30px" }} id='icon'></i>
                  </a>

                </div>
              </div>
            </Nav>
          </Navbar>
        </Container>
        
        <div className="text-center">
          <p style={{ color: '#D4D4D4' }}>© 2024 Copyright : Made with Passion by<br></br> Sumago Infotech</p>
        </div>
      </div>
    </>
  );
}

export default Footer1;
