import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MdOutlineHorizontalRule } from "react-icons/md";
import '../../CSS/accordion_page.css'
import faqimg from '../../IMG/Faqimg.png'
import { Col, Container, Image, Row } from 'react-bootstrap';

export default function DisabledAccordion() {
  return (
    <div>
        <h3 id='title' className='mb-4 mt-2'>FAQ</h3>
        <Container fluid>
            <Row>
                <Col lg={6} md={6} sm={12}>
                <h3 id='panelname' className='mt-3 mb-4'>Frequently Asked Questions</h3>
                <Accordion>
        <AccordionSummary
          expandIcon={<MdOutlineHorizontalRule style={{color:'white' }}/>}
          aria-controls="panel1-content"
          id="panel1-header"
         style={{backgroundColor:'#243663', color : 'white' , borderRadius: '50px'  , padding:'5px' ,paddingLeft:'50px'}}
        >
          <Typography >How can Trakify's benefit my construction business?</Typography>
        </AccordionSummary>
        <AccordionDetails id='panelpara'>
          <Typography style={{padding:'10px'}}>
          Trakify can help improve efficiency, productivity, and communication on your construction sites. By providing centralized management tools, real-time updates, and streamlined workflows, it enables you to optimize resource allocation, track progress more effectively, and ensure projects are completed on time and within budget.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='mt-3'>
        <AccordionSummary
          expandIcon={<MdOutlineHorizontalRule style={{color:'white'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{backgroundColor:'#243663', color : 'white' , borderRadius: '50px' , padding:'5px' , paddingLeft:'50px'}}
        >
          <Typography>Is Trakify's easy to use?
          </Typography>
        </AccordionSummary>
        <AccordionDetails id='panelpara'>
          <Typography style={{padding:'10px'}}>
          Yes, Trakify features an intuitive and user-friendly interface designed with builders in mind. It's easy to navigate, requires minimal training, and offers customizable options to adapt to your specific workflows and preferences.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='mt-3'>
        <AccordionSummary
          expandIcon={<MdOutlineHorizontalRule style={{color:'white'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{backgroundColor:'#243663', color : 'white' , borderRadius: '50px' , padding:'5px' , paddingLeft:'50px'}}
        >
          <Typography>Is customer support available for Trakify's?
          </Typography>
        </AccordionSummary>
        <AccordionDetails id='panelpara'>
          <Typography style={{padding:'10px'}}>
          Absolutely. We provide dedicated customer support to assist you with any questions, issues, or technical challenges you may encounter. Our team is committed to ensuring you get the most out of your Trakify experience.

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='mt-3'>
        <AccordionSummary
          expandIcon={<MdOutlineHorizontalRule style={{color:'white'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{backgroundColor:'#243663', color : 'white' , borderRadius: '50px' , padding:'5px' , paddingLeft:'50px'}}
        >
          <Typography>Is my data secure with Trakify's?
          </Typography>
        </AccordionSummary>
        <AccordionDetails id='panelpara'>
          <Typography style={{padding:'10px'}}>
          Yes, we take data security and privacy seriously. Trakify employs industry-standard security measures to safeguard your data, including encryption, regular backups, and access controls, ensuring your information remains safe and confidential.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='mt-3'>
        <AccordionSummary
          expandIcon={<MdOutlineHorizontalRule style={{color:'white'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{backgroundColor:'#243663', color : 'white' , borderRadius: '50px' ,padding:'5px', paddingLeft:'50px'}}
        >
          <Typography>How can I get started with Trakify's?</Typography>
        </AccordionSummary>
        <AccordionDetails id='panelpara'>
          <Typography style={{padding:'10px'}}>
          Getting started with Trakify is you just have to <b>contact on </b>
          +91 8010385237
          <b>Mail-id:</b> business@sumagoinfotech.com 

          </Typography>
        </AccordionDetails>
      </Accordion>
      <br></br>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <Image src={faqimg} fluid id='Faq_img'/>
                </Col>
            </Row>
        </Container>
      
    </div>
  );
}






















// import * as React from 'react';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import { Col, Container, Row } from 'react-bootstrap';
// import Image from 'react-bootstrap/Image';
// import '../../CSS/accordion_page.css'
// import faqimg from '../../IMG/Faqimg.png'

// export default function AccordionUsage() {
//     return (
//         <>
//         <h2 id='title' className='mt-4'>FAQ</h2>
//             <Container fluid className='mt-3'>
//                 <Row>
//                     <Col lg={6} md={6} sm={12}>
//                         <h4 style={{textAlign:'center'}}><b>Frequently Asked Questions</b></h4>
//                         <Accordion className='mt-4'>
//                             <AccordionSummary
//                                 aria-controls="panel1-content"
//                                 id="panel1-header"
//                                 style={{ backgroundColor: '#243663', borderRadius: '50px', color: 'white', padding:'6px', paddingLeft:'40px'}}

//                             >
//                                 What types of construction projects do you handle?
//                             </AccordionSummary>
//                             <AccordionDetails style={{ backgroundColor: '#CDEEFC', color: 'black', borderRadius: '50px' , padding:'20px', paddingLeft:'40px'}}>
//                                 "Save time and effort with a centralized platform for managing flat statuses."
//                             </AccordionDetails>
//                         </Accordion>
//                         <Accordion className='mt-2'>
//                             <AccordionSummary
//                                 aria-controls="panel2-content"
//                                 id="panel2-header"
//                                 style={{ backgroundColor: '#243663', borderRadius: '50px', color: 'white', padding:'6px', paddingLeft:'40px'}}
//                             >
//                                 How can TraKify benefit my construction business?
//                             </AccordionSummary>
//                             <AccordionDetails style={{ backgroundColor: '#CDEEFC', color: 'black', borderRadius: '50px' , padding:'20px', paddingLeft:'40px'}}>
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//                                 malesuada lacus ex, sit amet blandit leo lobortis eget.
//                             </AccordionDetails>
//                         </Accordion>
//                         <Accordion className='mt-2'>
//                             <AccordionSummary
//                                 aria-controls="panel2-content"
//                                 id="panel2-header"
//                                 style={{ backgroundColor: '#243663', borderRadius: '50px', color: 'white', padding:'6px', paddingLeft:'40px'}}
//                             >
//                                 Is TraKify easy to use?
//                             </AccordionSummary>
//                             <AccordionDetails style={{ backgroundColor: '#CDEEFC', color: 'black', borderRadius: '50px' , padding:'20px', paddingLeft:'40px'}}>
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//                                 malesuada lacus ex, sit amet blandit leo lobortis eget.
//                             </AccordionDetails>
//                         </Accordion>

//                         <Accordion className='mt-2'>
//                             <AccordionSummary
//                                 aria-controls="panel2-content"
//                                 id="panel2-header"
//                                 style={{ backgroundColor: '#243663', borderRadius: '50px', color: 'white', padding:'6px', paddingLeft:'40px'}}
//                             >
//                                 Is customer support available for Tracify?
//                             </AccordionSummary>
//                             <AccordionDetails style={{ backgroundColor: '#CDEEFC', color: 'black', borderRadius: '50px' , padding:'20px', paddingLeft:'40px'}}>
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//                                 malesuada lacus ex, sit amet blandit leo lobortis eget.
//                             </AccordionDetails>
//                         </Accordion>

//                         <Accordion className='mt-2'>
//                             <AccordionSummary
//                                 aria-controls="panel2-content"
//                                 id="panel2-header"
//                                 style={{ backgroundColor: '#243663', borderRadius: '50px', color: 'white', padding:'6px', paddingLeft:'40px'}}
//                             >
//                                 Is my data secure with TraKify?
//                             </AccordionSummary>
//                             <AccordionDetails style={{ backgroundColor: '#CDEEFC', color: 'black', borderRadius: '50px' , padding:'20px', paddingLeft:'40px'}}>
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//                                 malesuada lacus ex, sit amet blandit leo lobortis eget.
//                             </AccordionDetails>
//                         </Accordion>

//                         <Accordion className='mt-2'>
//                             <AccordionSummary
//                                 aria-controls="panel2-content"
//                                 id="panel2-header"
//                                 style={{ backgroundColor: '#243663', borderRadius: '50px', color: 'white', padding:'6px', paddingLeft:'40px'}}
//                             >
//                                 How can I get started with TraKify?
//                             </AccordionSummary>
//                             <AccordionDetails style={{ backgroundColor: '#CDEEFC', color: 'black', borderRadius: '50px' , padding:'20px', paddingLeft:'40px'}}>
//                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//                                 malesuada lacus ex, sit amet blandit leo lobortis eget.
//                             </AccordionDetails>
//                         </Accordion>
//                         <br></br>
//                     </Col>
                    
//                     <Col lg={6} md={6} sm={12}>
//                         <Image src={faqimg} fluid id='Faq_img'/>
//                     </Col>
//                 </Row>
//             </Container>
//         </>
//     );
// }
