import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import '../CSS/cards.css'
const Responsive_arrow = ({ mobileSrc, desktopSrc }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        // <img src={mobileSrc} alt="Mobile View"  className=' img-fluid' width={'100%'} />
        <Image src={mobileSrc} fluid id='img_arrow'/>
      ) : (
        <Image src={desktopSrc} fluid id='img_arrow'/>
      )}
    </div>
  );
};

export default Responsive_arrow;