import React from 'react'
import Image from 'react-bootstrap/Image';
import featuretrakify_img from '../IMG/feature of trakify_img.png'
import mobileview from '../IMG/trakify feature mobile view.png'
import ResponsiveImage from '../Components/Responsive_img/ResponsiveImage';
function Feature_of_trakify() {
    return (
        <section id='services'>
            {/* <ResponsiveImage mobileSrc={mobileview} desktopSrc={featuretrakify_img} /> */}
             <Image src={featuretrakify_img} fluid style={{paddingTop:'50px'}}/>  
        </section>
    )
}

export default Feature_of_trakify
