import React from 'react';
import Image from 'react-bootstrap/Image';
import '../../CSS/footer.css';
import footerimg from '../../IMG/footer_backimg1.png';
import { Col, Container, Row } from 'react-bootstrap';
import Footer1 from './Footer1';

function Footer() {
    return (
        <section id='contact'>
            <div className='BACK_footer_img'>
                <Container>
                    <Row className="position-relative">
                        <Col lg={7} md={7} sm={12} id='footerpara'>
                            <h4>Download</h4>
                            <p id='title1'>Trakify Application Now</p>
                            <p>
                                Trakify's purpose is to provide builders and developers executives with a comprehensive and user-friendly application to streamline the tracking and management of flats post-construction. The application aims to enhance the efficiency of flat showcases by allowing executives to easily select, update, and communicate the status of individual flats within a given project.<br></br><br></br>
                                  
                                <b style={{fontFamily:'Century Gothic'}}>Contact details : </b><a href="tel:5551234567" style={{color:'white'}} className='text-decoration-none'>+91 8010385237<br></br></a>
                                <b style={{fontFamily:'Century Gothic'}}>Mail-id: </b><a href='mailto:business@sumagoinfotech.com' style={{color:'white'}} className='text-decoration-none'>business@sumagoinfotech.com</a>

                            </p>
                        </Col>
                        <Col lg={5} md={5} sm={12} className="bannerform my-5 sticky-top">
                            <Container>
                                <div class="contact-form">
                                    <h2 className="py-3">CONTACT</h2>
                                    <form class="sticky-top" >
                                        <input
                                            type="text"
                                            name="fullName"
                                            className=" bannerinp"
                                            placeholder="Full name"
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                            className=" bannerinp"
                                            required
                                        />

                                        <input
                                            type="tel"
                                            name="mobileNumber"
                                            placeholder="Mobile Number"
                                            className=" bannerinp"
                                            required
                                        />


                                        <textarea
                                            name="message"
                                            placeholder="Message"
                                            className=" bannertxtarea"
                                            required
                                        >

                                        </textarea>
                                        <button
                                            type="submit"
                                            className="bannerbtn px-4 py-2 m-3 me-4 float-end"
                                        >
                                            Send
                                        </button>
                                    </form>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                {/* <Footer1/> */}
            </div>
        </section>
    );
}

export default Footer;
