import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logonav from '../../IMG/logo header.png'
import '../../CSS/navigation_nav.css'
function Navigation_bar() {
    const [expanded, setExpanded] = useState(false);

    const handleSelect = () => {
        setExpanded(false);
    };
    return (
        <>

            <Navbar expand="lg" className='d-none d-md-block' >
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link href="#home"><img src={logonav} alt='..' className='img-fluid' width={'160px'} /></Nav.Link>
                        <Nav.Link href="#" className='mt-4 text-black' id='item' ><b>HOME</b></Nav.Link>
                        <Nav.Link href="#about" className='mt-4 text-black' id='item'><b>ABOUT US</b></Nav.Link>
                        <Nav.Link href='#product' className='mt-4 text-black' id='item' ><b>PRODUCT</b></Nav.Link>
                        <Nav.Link href="#contact" className='mt-4 text-black' id='item' ><b>CONTACT</b></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>



            <Navbar expand="lg" className="bg-body-tertiary d-block d-md-none" style={{ position: 'absolute', width: '100%', zIndex: '1' }} expanded={expanded}>
            <Container className='toggle_btn_mobile'>
                <Navbar.Brand href="#home">
                    <img src={logonav} alt='..' className='img-fluid' width={'100px'} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto" onSelect={handleSelect}>
                        <Nav.Link href="#home" className='mt-4 text-black'><b>HOME</b></Nav.Link>
                        <Nav.Link href="#about" className='mt-4 text-black'><b>ABOUT US</b></Nav.Link>
                        <Nav.Link href="#contact" className='mt-4 text-black'><b>CONTACT</b></Nav.Link>
                        <Nav.Link href='#product' className='mt-4 text-black'><b>PRODUCT</b></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
            
        </>
    )
}

export default Navigation_bar

