import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import '../../CSS/Benefits.css'
import benefits_img1 from '../../IMG/Ehanceed_img1.png'
import benefits_img2 from '../../IMG/Improved_communication.png'
import benefits_img3 from '../../IMG/Data-driven.png'
import benefits_img4 from '../../IMG/Secure reliable.png'
import shadow_img from '../../IMG/shadowimg.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
function Benefits() {
    return (
        <>
            <h1 style={{ textAlign: 'center', letterSpacing: '10px' }} className='benefits_card'>BENEFITS</h1>
            <Container fluid className='mt-5'>
                <Row>
                    <Col lg={3} md={2} sm={12}>
                        <Card id='benefits_card'>
                            <Card.Img variant="top" src={benefits_img1} />
                        </Card>
                        <br></br>
                    </Col>
                    <br></br>
                    <Col lg={3} md={2} sm={12}>
                        <Card id='benefits_card'>
                            <Card.Img variant="top" src={benefits_img2} />
                        </Card>
                        <br></br>
                    </Col>
                    <br></br>
                    <Col lg={3} md={2} sm={12}>
                        <Card id='benefits_card'>
                            <Card.Img variant="top" src={benefits_img3} />
                        </Card>
                        <br></br>
                    </Col>
                    <br></br>
                    <Col lg={3} md={2} sm={12}>
                        <Card id='benefits_card'>
                            <Card.Img variant="top" src={benefits_img4} />
                        </Card>
                        <br></br>
                    </Col>
                    <br></br>
                </Row>
                <br></br>
            </Container>
        </>
    )
}

export default Benefits
