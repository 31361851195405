import React from 'react'
import Image from 'react-bootstrap/Image';
import bulding_photo from '../../IMG/bulding1.png'
import bulding_photo2 from '../../IMG/building2.png'
import bulding_photo3 from '../../IMG/building 3.png'
import '../../CSS/Header.css'
import { Col, Container, Row } from 'react-bootstrap';
function Buildingimg() {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} md={6} sm={12}></Col>
                    <Col lg={6} md={6} sm={12}>
                        <Row>
                            <Col lg={3} md={3} sm={12} >
                                <Image src={bulding_photo} fluid id='header_imgs' style={{ height: '275px' }} />
                            </Col>
                            <Col lg={3} md={3} sm={12} >
                                <Image src={bulding_photo2} fluid id='header_imgs' />
                            </Col>
                            <Col lg={6} md={6} sm={12} >
                                <Image src={bulding_photo3} fluid id='header_imgs' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Buildingimg
